<template>
    <div class="form__group">
        <label :for="name" class="form__label">
            {{ label }}
        </label>
        <div class="form__toolbar">
            <a href="#" @click.prevent="toggleBold"
               :class="['form__toolbar-button', {'form__toolbar-button--active': isBold}]">
                <font-icon :icon="['fa', 'bold']" class=""/>
            </a>
            <a href="#" @click.prevent="toggleItalic"
               :class="['form__toolbar-button', {'form__toolbar-button--active': isItalic }]">
                <font-icon :icon="['fa', 'italic']"/>
            </a>
            <template v-if="mode === 'rich'">
                <a href="#" @click.prevent="toggleBulletList"
                   :class="['form__toolbar-button', {'form__toolbar-button--active': isBulletList }]">
                    <font-icon :icon="['fa', 'list-ul']"/>
                </a>
                <a href="#" @click.prevent="toggleOrderedList"
                   :class="['form__toolbar-button', {'form__toolbar-button--active': isOrderedList }]">
                    <font-icon :icon="['fa', 'list-ol']"/>
                </a>
                <a href="#" @click.prevent="toggleHeading(2)"
                   :class="['form__toolbar-button', {'form__toolbar-button--active': isHeadingActive(2) }]">
                    <strong>H2</strong>
                </a>
                <a href="#" @click.prevent="toggleHeading(3)"
                   :class="['form__toolbar-button', {'form__toolbar-button--active': isHeadingActive(3) }]">
                    <strong>H3</strong>
                </a>
                <a href="#" @click.prevent="toggleHeading(4)"
                   :class="['form__toolbar-button', {'form__toolbar-button--active': isHeadingActive(4) }]">
                    <strong>H4</strong>
                </a>
            </template>
        </div>
        <div class="form__input-wrapper">
            <editor-content
                :class="['form__input', {'form__input--invalid': error}]"
                :editor="editor" />
        </div>
        <span class="form__error" v-if="errorMessage">
      <font-icon :icon="['fa', 'warning']" class="form__error-icon"/> {{ errorMessage }}
    </span>
        <span class="form__help" v-else-if="help">
      <font-icon :icon="['fa', 'circle-info']" class="form__help-icon"/> {{ help }}
    </span>
    </div>
</template>

<script setup>
import {defineProps, ref, watch, computed} from 'vue';
import {EditorContent, useEditor} from '@tiptap/vue-3';
import { Node } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Heading from '@tiptap/extension-heading';
import HardBreak from '@tiptap/extension-hard-break';

const errorMessage = ref('');

const props = defineProps({
    modelValue: String,
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    help: {
        type: String,
        required: false,
    },
    error: {
        type: String,
    },
    mode: {
        type: String,
        default: 'simple',
        validator(value) {
            return ['simple', 'rich'].includes(value)
        }
    }
});

const emit = defineEmits(['update:modelValue']);

const getExtensions = () => {
    if (props.mode === 'rich') {
        return [
            Document,
            Paragraph,
            Text,
            Bold,
            Italic,
            BulletList,
            ListItem,
            OrderedList,
            Heading.configure({
                levels: [2, 3, 4],
            }),
            HardBreak,
        ];
    }

    const TextDocument = Node.create({
        name: 'doc',
        topNode: true,
        content: 'text*',
    })

    return [TextDocument, Text, Bold, Italic];
}

const editor = useEditor({
    content: props.modelValue,
    extensions: getExtensions(),
    onUpdate: ({editor}) => {
        errorMessage.value = '';
        emit('update:modelValue', editor.getHTML())
    }
})

const toggleBold = () => {
    editor.value?.chain().focus().toggleBold().run();
}

const toggleItalic = () => {
    editor.value?.chain().focus().toggleItalic().run();
}

const toggleBulletList = () => {
    editor.value?.chain().focus().toggleBulletList().run();
}

const toggleOrderedList = () => {
    editor.value?.chain().focus().toggleOrderedList().run();
}

const toggleHeading = (level) => {
    editor.value?.chain().focus().toggleHeading({level}).run();
}

const isBold = computed(() => {
    return editor.value?.isActive('bold');
});

const isItalic = computed(() => {
    return editor.value?.isActive('italic');
});

const isBulletList = computed(() => {
    return editor.value?.isActive('bulletList');
});

const isOrderedList = computed(() => {
    return editor.value?.isActive('orderedList');
});

const isHeadingActive = computed(() => {
    return (level) => editor.value?.isActive('heading', {level});
});

watch(() => props.error, (newValue) => {
    errorMessage.value = newValue;
}, {immediate: true});

</script>