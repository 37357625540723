<template>
    {{ formatDate() }}
</template>

<script setup>
import {defineProps} from 'vue';

const props = defineProps({
    date: {
        type: String,
    },
    withTime: {
        type: Boolean,
        default: false,
    }
})

const rawDate = new Date(props.date);

const dateOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
const timeOptions = {hour: 'numeric', minute: 'numeric'};

const formatDate = () => {
    if (props.date === undefined || props.date === null) {
        return 'Brak';
    }

    let date = rawDate.toLocaleDateString("pl-PL", dateOptions);

    if (props.withTime) {
        date += ' ' + rawDate.toLocaleTimeString("pl-PL", timeOptions);
    }

    return date;
};

</script>