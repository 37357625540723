<template>
    <span :class="['status', 'status--' + status]">{{ formatStatus() }}</span>
</template>

<script setup>
import {defineProps} from 'vue';

const props = defineProps({
    status: {
        type: String,
        required: true
    },
})

const statues = {
    'active': 'opublikowany',
    'hidden': 'ukryty',
    'inactive': 'wyłączony',
}

const formatStatus = () => {
    return statues[props.status];
}

</script>