<template>
    <section class="login">
        <img src="/img/logo.svg" class="login__logo">

        <div class="login__box">
            <h2 class="command__heading">Wybierz siedzibę</h2>
            <a v-for="branch in branchService.getAvailableBranches()" :key="branch.id"
               class="button button--tertiary button--full login__branch-button"
               @click.prevent="selectBranch(branch.id)">
                {{ branch.name }}
            </a>
        </div>
    </section>
</template>

<script setup>
import {useRouter} from 'vue-router';
import useBranch from '@/services/branch/branch';

const router = useRouter();
const branchService = useBranch();

const selectBranch = (id) => {
    branchService.setCurrentBranchId(id);
    router.replace({name: 'dashboard'});
}
</script>