<template>
    <div class="form__group">
        <label :for="name" class="form__label">{{ label }}</label>
        <div :class="['select', 'form__input-wrapper', {'select--is-open': isOpen, 'form__input--invalid': error}]">

            <div :class="['select__input', 'form__input', {'form__input--invalid': false}]"
                 @click.prevent="toggle()">
                <image-presenter v-if="selectedImage" :image="selectedImage.image" size="thumb" class="select__image"/>
                <image-presenter v-else-if="image" :image="image" size="thumb" class="select__image"/>
                <span v-else>Wybierz zdjęcie</span>
            </div>
            <a href="#" class="form__input-button" @click.prevent="toggle()">
                <FontIcon :icon="['fa', 'image']"/>
            </a>

            <Teleport to="body">
                <div class="picker__menu-overlay" v-if="isOpen">
                    <div class="picker__menu">
                        <div class="picker__menu-header">
                            <h2 class="picker__menu-title">Wybierz zdjęcie</h2>
                            <a href="#" @click.prevent="toggle" class="picker__menu-close">
                                <font-icon :icon="['fa', 'close']"/>
                            </a>
                        </div>
                        <div class="form__group">
                            <div class="form__input-wrapper">
                                <input v-model="searchText" @input="search" class="form__input" placeholder="Szukaj...">
                                <spinner size="small" class="form__input-spinner" v-show="loading"/>
                            </div>
                        </div>
                        <div class="picker__options">
                            <template v-if="images.length">
                                <div :class="['picker__option', 'picker__option-' + resultsLayout]"
                                     v-for="image in images"
                                     :key="image.id" @click.prevent="select(image)">
                                    <image-presenter :image="image.image"
                                                     size="thumb"
                                                     :alt="image.description"
                                                     class="picker__option-image"
                                                     @mouseover="hoverImageDescription = image.description"
                                                     @mouseout="hoverImageDescription = ''"
                                    />
                                </div>
                            </template>
                            <template v-else>
                                Nie znaleziono zdjęć.
                            </template>
                        </div>
                        <div class="picker__menu-footer">{{ hoverImageDescription }}</div>
                    </div>
                </div>
            </Teleport>
        </div>

        <div class="form__error" v-if="errorMessage">
            <font-icon :icon="['fa', 'warning']" class="form__error-icon"/>
            {{ errorMessage }}
        </div>
        <div class="form__help" v-else-if="help">
            <font-icon :icon="['fa', 'circle-info']" class="form__help-icon"/>
            {{ help }}
        </div>
    </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, watch} from 'vue';
import useImages from '@/composables/useImages';

const selectedImage = ref(null);
const searchText = ref('');
const isOpen = ref(false);
const errorMessage = ref('');
const hoverImageDescription = ref('');

const {loading, data: images, getImages} = useImages();

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    type: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: true,
        default: 'Zdjęcie',
    },
    help: {
        type: String,
        required: false,
    },
    image: {
        type: Object,
        required: false,
    },
    resultsLayout: {
        type: String,
        required: true,
        default: 'one-third',
        validator(value) {
            return ['full', 'half', 'one-third', 'one-quarter'].includes(value)
        }
    },
    error: {
        type: String,
    }
});

getImages(props.type);

const search = async () => {
    getImages(props.type, searchText.value);
}

const select = (image) => {
    isOpen.value = false;
    selectedImage.value = image;
    errorMessage.value = '';
    emit('update:modelValue', image.id);
}

const toggle = () => {
    isOpen.value = !isOpen.value;
}

watch(() => props.error, (newValue) => {
    errorMessage.value = newValue;
}, {immediate: true});

</script>