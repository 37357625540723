<template>
  <div class="notifications">
    <div v-for="notification in notifications"
         :key="notification.id"
         :class="['notification', 'notification--' + notification.type]">
      <a href="#" @click.prevent="removeNotification(notification.id)" class="notification__close">
        <font-icon :icon="['fa', 'close']" />
      </a>
      <p class="notification__message">{{ notification.message }}</p>
    </div>
  </div>
</template>

<script setup>

import useNotifications from "@/composables/useNotifications";

const {notifications, removeNotification} = useNotifications();
</script>
