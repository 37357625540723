import { createRouter, createWebHistory } from 'vue-router';

import Dashboard from '@/pages/Dashboard.vue';
import Login from '@/pages/Login.vue';
import BranchPage from '@/pages/BranchPage.vue';
import modules from '@/router/modules';
import {useAuth} from '@/services/auth/auth';

const auth = useAuth();

let routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'BaseLayout',
      public: true,
    }
  },
  {
    path: '/branch',
    name: 'branch',
    component: BranchPage,
    meta: {
      layout: 'BaseLayout',
      public: false,
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/pages/NotFound.vue')
  }
]

for (let module of modules) {
  routes = routes.concat(module);
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: 'active',
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
  },
});

router.beforeEach(async (to) => {
  if (!to.meta.public && !auth.isAuthenticated()) {
    return {
      name: 'login'
    }
  }
});

export default router
