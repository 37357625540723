let availableBranches = [];

export default function useBranch() {
    const setCurrentBranchId = (id) => {
        localStorage.setItem('branchId', id);
    }

    const getCurrentBranch = () => {
        return availableBranches.find(branch => branch.id === getCurrentBranchId());
    }

    const getCurrentBranchId = () => {
        return localStorage.getItem('branchId');
    }

    const setAvailableBranches = (branches) => {
        availableBranches = branches;
    }

    const getAvailableBranches = () => {
        return availableBranches;
    }

    return {
        setCurrentBranchId,
        getCurrentBranchId,
        setAvailableBranches,
        getAvailableBranches,
        getCurrentBranch
    }
}