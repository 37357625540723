<template>
    {{ formatMoney() }}
</template>

<script setup>
import {defineProps} from 'vue';

const props = defineProps({
    value: {
        type: Number,
    },
})

const formatMoney = () => {
    return new Intl.NumberFormat('pl-PL', {style: 'currency', currency: 'PLN'})
        .format(props.value / 100);
}

</script>