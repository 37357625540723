<template>
  <div class="command" v-if="loaded">
    <slot name="title"><h1 class="heading--primary"></h1></slot>
    <a href="#" class="command__close" @click.prevent="router.back()">
      <font-icon :icon="['fa', 'arrow-left']" />
    </a>
    <main class="command__body">
      <slot></slot>
    </main>
  </div>
</template>

<script setup>
import {useAuth} from "@/services/auth/auth";
import {onBeforeMount, ref} from "vue";
import {useRouter} from "vue-router";

const auth = useAuth();
const loaded = ref(false);

const router = useRouter();

onBeforeMount(async () => {
  const {isSuccess} = await auth.fetchEmployee();

  if(isSuccess) {
    loaded.value = true;
  }
});
</script>