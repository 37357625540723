export default [
    {
        path: '/events',
        name: 'events.index',
        component: () => import('@/modules/catalog/pages/events/BrowseEvents')
    },
    {
        path: '/events/:id',
        name: 'events.show',
        component: () => import('@/modules/catalog/pages/events/ShowEvent'),
        props: true,
    },
    {
        path: '/events/create',
        name: 'events.create',
        component: () => import('@/modules/catalog/pages/events/AddEvent'),
    },
    {
        path: '/events/edit/:id',
        name: 'events.edit',
        component: () => import('@/modules/catalog/pages/events/EditEvent'),
        props: true,
    },
    {
        path: '/eventCategories',
        name: 'eventCategories.index',
        component: () => import('@/modules/catalog/pages/eventCategories/BrowseEventCategories')
    },
    {
        path: '/eventCategories/create',
        name: 'eventCategories.create',
        component: () => import('@/modules/catalog/pages/eventCategories/AddEventCategory'),
    },
    {
        path: '/eventCategories/:id',
        name: 'eventCategories.show',
        component: () => import('@/modules/catalog/pages/eventCategories/ShowEventCategory'),
        props: true,
    },
    {
        path: '/eventCategories/edit/:id',
        name: 'eventCategories.edit',
        component: () => import('@/modules/catalog/pages/eventCategories/EditEventCategory'),
        props: true,
    },
    {
        path: '/teachers',
        name: 'teachers.index',
        component: () => import('@/modules/catalog/pages/teachers/BrowseTeachers')
    },
    {
        path: '/teachers/create',
        name: 'teachers.create',
        component: () => import('@/modules/catalog/pages/teachers/AddTeacher'),
    },
    {
        path: '/teachers/edit/:id',
        name: 'teachers.edit',
        component: () => import('@/modules/catalog/pages/teachers/EditTeacher'),
        props: true,
    },
    {
        path: '/reviews',
        name: 'reviews.index',
        component: () => import('@/modules/catalog/pages/reviews/BrowseReviews'),
    },
    {
        path: '/reviews/create',
        name: 'reviews.create',
        component: () => import('@/modules/catalog/pages/reviews/AddReview'),
    },
    {
        path: '/reviews/edit/:id',
        name: 'reviews.edit',
        component: () => import('@/modules/catalog/pages/reviews/EditReview'),
        props: true,
    },
    {
        path: '/courses',
        name: 'courses.index',
        component: () => import('@/modules/catalog/pages/courses/BrowseCourses')
    },
    {
        path: '/courses/create',
        name: 'courses.create',
        component: () => import('@/modules/catalog/pages/courses/AddCourse'),
    },
    {
        path: '/courses/edit/:id',
        name: 'courses.edit',
        component: () => import('@/modules/catalog/pages/courses/EditCourse'),
        props: true,
    },
    {
        path: '/courses/:id',
        name: 'courses.show',
        component: () => import('@/modules/catalog/pages/courses/ShowCourse'),
        props: true,
    },
    {
        path: '/courses/delete/:id',
        name: 'courses.delete',
        component: () => import('@/modules/catalog/pages/courses/DeleteCourse'),
        props: true,
        meta: {
            layout: 'CommandLayout'
        }
    },
    {
        path: '/pages',
        name: 'pages.index',
        component: () => import('@/modules/catalog/pages/pages/BrowsePages')
    },
    {
        path: '/pages/:id',
        name: 'pages.show',
        component: () => import('@/modules/catalog/pages/pages/ShowPage'),
        props: true,
    },
    {
        path: '/pages/create',
        name: 'pages.create',
        component: () => import('@/modules/catalog/pages/pages/AddPage'),
        props: true,
    },
    {
        path: '/pages/edit/:id',
        name: 'pages.edit',
        component: () => import('@/modules/catalog/pages/pages/EditPage'),
        props: true,
    },
    {
        path: '/pageData/create/:type/:relatedId/',
        name: 'pageData.create',
        component: () => import('@/modules/catalog/pages/pageData/AddPageData'),
        props: true,
    },
    {
        path: '/pageData/edit/:id/:relatedId',
        name: 'pageData.edit',
        component: () => import('@/modules/catalog/pages/pageData/EditPageData'),
        props: true,
    },
    {
        path: '/images',
        name: 'images.index',
        component: () => import('@/modules/catalog/pages/images/BrowseImages')
    },
    {
        path: '/images/create',
        name: 'images.create',
        component: () => import('@/modules/catalog/pages/images/AddImage')
    },
    {
        path: '/images/edit/:id',
        name: 'images.edit',
        component: () => import('@/modules/catalog/pages/images/EditImage'),
        props: true,
    },
    {
        path: '/news',
        name: 'news.index',
        component: () => import('@/modules/catalog/pages/news/BrowseNews'),
        props: true,
    },
    {
        path: '/news/:id',
        name: 'news.show',
        component: () => import('@/modules/catalog/pages/news/ShowNews'),
        props: true,
    },
    {
        path: '/news/create',
        name: 'news.create',
        component: () => import('@/modules/catalog/pages/news/AddNews')
    },
    {
        path: '/news/edit/:id',
        name: 'news.edit',
        component: () => import('@/modules/catalog/pages/news/EditNews'),
        props: true,
    },
];