<template>
    <header class="header">
        <router-link :to="{ name: 'dashboard' }" class="header__brand">
            <img src="/img/hiromi_primary.svg" class="header__logo">
        </router-link>

        <div class="header__branch" ref="branchMenu" @click.prevent="isBranchMenuOpen = !isBranchMenuOpen">
            {{ branchService.getCurrentBranch().name }}
            <FontIcon class="header__branch-icon" :icon="['fa', 'sync-alt']"/>

            <div class="header__menu" v-if="isBranchMenuOpen">
                <a v-for="branch in branchService.getAvailableBranches()" :key="branch.id"
                   href="#" class="header__menu-link" @click.prevent="changeBranch(branch.id)">
                    {{ branch.name }}
                </a>
            </div>
        </div>
        <div class="header__account" ref="accountMenu" @click.prevent="isAccountMenuOpen = !isAccountMenuOpen">
            <FontIcon class="header__icon" :icon="['fa', 'user']"/>
            <div class="header__menu" v-if="isAccountMenuOpen">
                <span class="header__menu-link">{{ fullName }}</span>
                <a href="#" @click.prevent="logout" class="header__menu-link">Wyloguj</a>
            </div>
        </div>
        <a @click.prevent="toggle" href="#" class="header__navigation">
            <FontIcon class="header__icon" :icon="['fa', 'bars']"/>
        </a>
    </header>
</template>

<script setup>
import {computed, ref, onBeforeUnmount} from 'vue';
import {useAuth} from '@/services/auth/auth';
import useBranch from '@/services/branch/branch';
import useNavigation from '@/composables/useNavigation';

const auth = useAuth();
const {toggle} = useNavigation();
const branchService = useBranch();
const employee = auth.getEmployee();

let isBranchMenuOpen = ref(false);
let isAccountMenuOpen = ref(false);
const branchMenu = ref(null);
const accountMenu = ref(null);

const fullName = computed(() => {
    return `${employee.firstName} ${employee.lastName}`;
})

const onWindowClick = (e) => {
    if (!branchMenu.value.contains(e.target)) {
        isBranchMenuOpen.value = false;
    }

    if(!accountMenu.value.contains(e.target)) {
        isAccountMenuOpen.value = false;
    }
}

window.addEventListener('click', onWindowClick);

onBeforeUnmount(() => {
    window.removeEventListener('click', onWindowClick);
})

const logout = () => {
    auth.logout();
}

const changeBranch = (branchId) => {
    branchService.setCurrentBranchId(branchId);
    window.location.reload();
}
</script>