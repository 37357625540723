import {reactive} from 'vue';
import { v4 as uuidv4 } from 'uuid';

const notifications = reactive([]);

export default function useNotifications() {
    const showNotification = (message, options = {}) => {
        const {
            type = 'success',
            autoHide = true,
            timeout = 6000
        } = options;

        notifications.push({
            'id': uuidv4(),
            message,
            type
        });

        if  (autoHide) {
            setTimeout(() => {
                removeNotification();
            }, timeout);
        }
    }

    const showSuccess = (message, options = {}) => {
        showNotification(message, {type: 'success', ...options});
    }

    const showWarning = (message, options = {}) => {
        showNotification(message, {type: 'warning', ...options});
    }

    const showError = (message, options = {}) => {
        showNotification(message, {type: 'error', ...options});
    }

    const showConstantNotification = (message, options = {}) => {
        showNotification(message, {autoHide: false, ...options});
    }

    const removeNotification = (id) => {
        const index = notifications.findIndex(item => item.id === id);
        notifications.splice(index, 1);
    }

    return {
        notifications,
        showNotification,
        showSuccess,
        showWarning,
        showError,
        showConstantNotification,
        removeNotification
    }
}