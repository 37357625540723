<template>
  <component :is="layout">
    <router-view/>
  </component>
  <the-notifications></the-notifications>
</template>

<script setup>
import {useRoute} from 'vue-router';
import {shallowRef, watch} from 'vue';
import TheNotifications from '@/components/TheNotifications';

const route = useRoute();
const layout = shallowRef('BaseLayout');

watch(() => route.meta, () => {
  if (route.meta.layout !== undefined) {
    layout.value = route.meta.layout;
  } else {
    layout.value = 'DashboardLayout';
  }
})

</script>