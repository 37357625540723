<template>
    <div class="form__buttons">
        <a href="#" class="button button--cancel" @click.prevent="onCancel">Anuluj</a>
        <submit-button @click.prevent="onSubmit" :loading="loading" :label="label"></submit-button>
    </div>
</template>

<script setup>
import {defineEmits, defineProps} from 'vue';
import SubmitButton from '@/components/form/SubmitButton';

defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    label: {
        type: String,
        default: 'Zapisz',
    }
});

const emit = defineEmits(['submitted', 'cancelled']);

const onSubmit = () => {
    emit('submitted');
}

const onCancel = () => {
    emit('cancelled');
}
</script>