<template>
    <section class="login">
        <img src="/img/logo.svg" class="login__logo">
        <div class="login__box">
            <form @submit.prevent="login" class="login__form">
                <form-input
                    v-model="form.email"
                    label="E-mail"
                    name="email"
                    :error="formErrors.email"/>
                <form-input
                    v-model="form.password"
                    label="Hasło"
                    type="password"
                    name="password"
                    :error="formErrors.password"/>
                <button v-if="!isLoading" class="button button--primary button--full" type="submit">zaloguj</button>
            </form>
        </div>

    </section>
</template>

<script setup>
import {reactive, ref} from 'vue';
import {useRouter} from 'vue-router';
import {useAuth} from '@/services/auth/auth';

const form = reactive({
    email: '',
    password: ''
});

const router = useRouter();
const auth = useAuth();

const formErrors = ref([]);
const isLoading = ref(false)

const login = async () => {
    isLoading.value = true;
    const {isSuccess, errors, showBranchScreen} = await auth.login(form.email, form.password);
    formErrors.value = errors.value;
    isLoading.value = false;

    if (isSuccess.value) {
        if (showBranchScreen) {
            await router.replace({name: 'branch'});
        } else {
            await router.replace({name: 'dashboard'});
        }
    }
}
</script>


