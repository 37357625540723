<template>
    <div class="form__group">
        <div class="form__label">
            {{ label }}
        </div>

        <span class="form__help form__help--top" v-if="help">
            <font-icon :icon="['fa', 'circle-info']" class="form__help-icon"/> {{ help }}
        </span>

        <label :for="name" class="form__switch">
            <input type="checkbox" :name="name" :id="name" v-model="isChecked" @change="onChange">
            <span class="form__switch-toggle"></span>
        </label>

        <span class="form__error" v-if="errorMessage">
            <font-icon :icon="['fa', 'warning']" class="form__error-icon"/> {{ errorMessage }}
        </span>
    </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, watch} from 'vue';

const errorMessage = ref('');

const props = defineProps({
    modelValue: Boolean,
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    help: {
        type: String,
        required: false,
    },
    error: {
        type: String,
    }
});

const emit = defineEmits(['update:modelValue']);
const isChecked = ref(props.modelValue);

watch(() => props.error, (newValue) => {
    errorMessage.value = newValue;
}, { immediate: true });

const onChange = () => {
    emit('update:modelValue', isChecked.value);
}

</script>