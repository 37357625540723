import useApi from '@/services/api/useApi';
import useBranch from '@/services/branch/branch';

export default function useImages() {
    const {loading, errors, data, isSuccess, get, post, put, destroy} = useApi();

    const getImages = async (type = 'all', searchQuery = '') => {
        return await get('catalog/images', {type, searchQuery});
    }

    const getImage = async (id) => {
        return await get(`catalog/images/${id}`);
    }

    const addImage = async (image, file) => {
        const branchService = useBranch();

        const formData = new FormData();
        formData.append('file', file);
        formData.append('description', image.description);
        formData.append('type', image.type);
        formData.append('isActive', image.isActive);
        formData.append('brightness', image.brightness);
        formData.append('cropLeft', parseInt(image.crop.left));
        formData.append('cropTop', parseInt(image.crop.top));
        formData.append('cropWidth', parseInt(image.crop.width));
        formData.append('cropHeight', parseInt(image.crop.height));
        formData.append('branchId', branchService.getCurrentBranchId());

        return await post('catalog/images', formData);
    }

    const updateImage = async (id, client) => {
        return await put(`catalog/images/${id}`, client);
    }

    const deleteImage = async (id) => {
        return await destroy(`catalog/images/${id}`);
    }

    const getImagesByStruct = async (structType, structId) => {
        return await get(`catalog/imageGalleries`, {structType, structId});
    }

    const syncImages = async (payload) => {
        return await put(`catalog/imageGalleries`, payload)
    }

    const getSettings = async () => {
        return await get('catalog/images/settings');
    }

    return {
        data,
        errors,
        loading,
        isSuccess,
        getImages,
        getImage,
        addImage,
        updateImage,
        deleteImage,
        getImagesByStruct,
        syncImages,
        getSettings
    }
}