<template>
    <div class="form__group">
        <label :for="name" class="form__label">
            {{ label }}
        </label>

        <div class="form__input-wrapper">
            <input
                :class="['form__input', {'form__input--invalid': error}]"
                :id="name"
                :name="name"
                ref="inputRef"
                type="text"
            />
        </div>

        <span class="form__error" v-if="errorMessage">
            <font-icon :icon="['fa', 'warning']" class="form__error-icon"/> {{ errorMessage }}
        </span>
        <span class="form__help" v-else-if="help">
            <font-icon :icon="['fa', 'circle-info']" class="form__help-icon"/> {{ help }}
        </span>
    </div>
</template>

<script setup>
import {defineProps, ref, watch} from 'vue';
import {useCurrencyInput} from 'vue-currency-input';

const errorMessage = ref('');

const props = defineProps({
    modelValue: Number,
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    help: {
        type: String,
        required: false,
    },
    error: {
        type: String,
    }
});

const { inputRef } = useCurrencyInput({
    currency: 'PLN',
    currencyDisplay: 'hidden',
    precision: 2,
    valueScaling: 'precision'
});

watch(() => props.error, (newValue) => {
    errorMessage.value = newValue;
}, { immediate: true });

</script>