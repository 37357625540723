<template>
    <div class="form__group">
        <label :for="name" class="form__label">
            {{ label }} <span class="form__counter" v-if="countCharacters">{{ charactersCount }}</span>
        </label>
        <div class="form__input-wrapper">
            <input :class="['form__input', {'form__input--invalid': error}]"
                   :value="modelValue"
                   :id="name"
                   :name="name"
                   :type="type"
                   :disabled="disabled"
                   @input="onInput"/>
        </div>

        <span class="form__error" v-if="errorMessage">
            <font-icon :icon="['fa', 'warning']" class="form__error-icon"/> {{ errorMessage }}
        </span>
        <span class="form__help" v-else-if="help">
            <font-icon :icon="['fa', 'circle-info']" class="form__help-icon"/> {{ help }}
        </span>
    </div>
</template>

<script setup>
import {defineProps, defineEmits, ref, watch, computed} from 'vue';

const errorMessage = ref('');

const props = defineProps({
    modelValue: String,
    name: {
        type: String,
        required: true,
    },
    type: {
        type: String,
        default: 'text',
    },
    label: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    help: {
        type: String,
        required: false,
    },
    error: {
        type: String,
    },
    countCharacters: {
        type: Boolean,
        default: false,
    }
});

const emit = defineEmits(['update:modelValue'])

const charactersCount = computed(() => props.modelValue.length);

watch(() => props.error, (newValue) => {
    errorMessage.value = newValue;
}, { immediate: true });

const onInput = (event) => {
    errorMessage.value = '';
    emit('update:modelValue', event.target.value)
}

</script>